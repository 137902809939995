import * as React from "react"
import {Link} from "gatsby"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const ForloverPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=forlover_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu changeLang={changeLang} lang={lang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Forlovere og toastmaster</h1>

                    <h2>Forlovere</h2>

                    <h3>Brudens forlovere</h3>
                    <p>
                        <strong>Siri Gamst Bøysen</strong> (sirigamstboeysen@gmail.com / <nobr>+47 977 45 502</nobr>) og <strong>Petter Bye</strong> (bye.petter@gmail.com / <nobr>+47 410 45 514</nobr>)
                    </p>
                    <h3>Brudgommens forlover</h3>
                    <p>
                        <strong>Andreas Baug</strong> (anbaug@hotmail.com / <nobr>+47 950 88 550</nobr>).
                    </p>


                    <h2>Toastmaster</h2>

                    <p>
                        Toastmaster for kvelden vil være <strong>Ole Helliesen</strong> (ole.helliesen@gmail.com / <nobr>+44 7825 933875</nobr>).
                    </p>
                </>
            }
            {lang == "en" &&
                <>
                    <h1>Matron of honour, best men & toastmaster</h1>

                    {/*<h2>Matron of honour & best men</h2>*/}

                    <h3>The brides matron of honour & best man</h3>
                    <p>
                        <strong>Siri Gamst Bøysen</strong> (sirigamstboeysen@gmail.com / <nobr>+47 977 45 502</nobr>) and <strong>Petter Bye</strong> (bye.petter@gmail.com / <nobr>+47 410 45 514</nobr>)
                    </p>
                    <h3>The groom's best man</h3>
                    <p>
                        <strong>Andreas Baug</strong> (anbaug@hotmail.com / <nobr>+47 950 88 550</nobr>).
                    </p>


                    <h2>Toastmaster</h2>

                    <p>
                        The evening's toastmaster is <strong>Ole Helliesen</strong> (ole.helliesen@gmail.com / <nobr>+44 7825 933875</nobr>).
                    </p>

                </>
            }

        </main>
    )
}

export default ForloverPage
